var configs : { [env:string]:IConfig; } = {
    ["staging"]: {
        basecampHost: "https://basecamp-stage.omy.run",
        //basecampHost: "http://localhost:51841",
        plannerHost: "https://planner-stage.omy.run",
        chatDirectorHost: "https://chat-stage.omy.run",
        //chatDirectorHost: "http://localhost:63149",
        //chatDirectorHost: "http://localhost:5005",
        auth0: {
            domain: "omyrun-stage.eu.auth0.com",
            clientId: "Cy27HKKoJ2z117mOYPGCxlJ7kxtMZv9O",
            audience: "https://basecamp-stage.omy.run"
        }
    },
    ["production"]: {
        basecampHost: "https://basecamp.omy.run",
        plannerHost: "https://planner.omy.run",
        chatDirectorHost: "https://chat.omy.run",
        auth0: {
            domain: "auth.omysports.ai",
            clientId: "JslUXRQUCLIW33GqE9frYfoWdoMYjcOL",
            audience: "https://basecamp.omy.run"
        }
    }
};

const reactAppEnv = process.env.REACT_APP_ENV || "staging";

export default configs[reactAppEnv];

export interface IConfig {
    basecampHost: string;
    plannerHost: string;
    chatDirectorHost: string;
    auth0: IAuth0Config;
}

export interface IAuth0Config {
    domain: string;
    clientId: string;
    audience: string;
}